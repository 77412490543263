<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.829" x2="0.356" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_shirt" transform="translate(-600 -2975)">
    <g data-name="Group 425" id="Group_425" transform="translate(225 -29)">
     <rect :fill="background" data-name="Rectangle 215" height="183" id="Rectangle_215" transform="translate(375 3004)" width="183"/>
     <path d="M90.409,13.821,62.523,0C59.644,3.982,53.27,6.76,45.837,6.76S32.03,3.982,29.151,0L1.265,13.821A2.309,2.309,0,0,0,.234,16.9L8.426,33.3a2.309,2.309,0,0,0,3.079,1.031l8.107-3.967a2.288,2.288,0,0,1,3.294,2.062V68.749a4.578,4.578,0,0,0,4.583,4.583H64.156a4.578,4.578,0,0,0,4.583-4.583V32.412a2.293,2.293,0,0,1,3.294-2.062l8.107,3.967a2.284,2.284,0,0,0,3.079-1.031L91.426,16.9a2.287,2.287,0,0,0-1.017-3.079Z" data-name="Icon awesome-tshirt" fill="url(#linear-gradient)" id="Icon_awesome-tshirt" transform="translate(420.666 3058.834)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>